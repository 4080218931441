<template>
  <invitations-list :loading="loading" :items="team.invitations" @canceled="uid => team.removeInvitation(uid)" />
</template>

<script>
  import {Invitation, Person} from '@/models'
  import InvitationsList from '@/modules/invitations/components/InvitationsList'

  export default {
    name: 'TeamInvitationsList',
    components: {InvitationsList},
    props: {
      team: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        loading: true
      }
    },
    computed: {
      user() {
        return uid => Person.get(uid)
      },
      invitations() {
        return this.team.invitations ? this.team.invitations.map(uid => Invitation.get(uid)) : []
      }
    },
    mounted() {
      this.$store.dispatch('teams/findOneInvitations', this.team.uid).finally(() => {
        this.loading = false
      })
    }
  }
</script>
